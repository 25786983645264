import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Constants
import { PURPLE_COLOR, scaleOptions } from '../../../constantsCommon';
import { cptacTissuesNames, graphTypes, proteinExpressionSortingOptions, sortingOptions } from './constants';
// Components
import Error from '../../common/Error/Error';
import Spinner from '../../common/Spinner/Spinner';
import NoData from '../../common/NoData/NoData';
import BarChartDiagram from '../../graphics/BarChartDiagram/BarChartDiagram';
import ModalComponent from '../../ModalComponent/ModalComponent';
import ViolinChart from '../../graphics/ViolinChart/ViolinChart';
import DatasetsSelector
  from '../../Workflows/Algorithms/SingleCellStudyViewer/Components/DatasetsSelector/DatasetsSelector';
import Button from '../../common/Buttons/Button/Button';
import SamplesChart from './Charts/SamplesChart/SamplesChart';
import HpaCharts from './Charts/HpaCharts/HpaCharts';
import CellTypeExpressionChart from './Charts/CellTypeExpressionChart/CellTypeExpressionChart';
import GroupedViolinChart from '../../graphics/GroupedViolinChart/GroupedViolinChart';
// Store
import * as SELECTORS from './selectors';
import * as ACTIONS from './reducer';
import { loadNextChapterAction } from '../actions';
import { hideTooltipAction, showTooltipAction } from '../../common/Tooltip/store/reducer';
import { shortConceptCardSetIdAction } from '../../Concept/ShortConceptCard/reducer';
import { isSingleCellAllowedSelector } from '../../Header/selectors';
import { toggleIsModalOpenAction } from './Charts/HpaCharts/components/CellTypeClusterModal/store/reducer';
// Utils
import {
  getCsvName,
  getChartName,
  getChartTitle,
  scaleTransformData,
  getIsSamplesDataExist,
} from './utils';
import { selectedTissuesColors } from '../../Workflows/Algorithms/SingleTargetForCancer/constants';
// Styles
import '../GeneDetails.scss';

const propTypes = {
  gene: PropTypes.string,
  initSectionData: PropTypes.func,
  loadNextChapter: PropTypes.func,
  itsNewConcept: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  geneName: PropTypes.string,
  showTooltip: PropTypes.func,
  hideTooltip: PropTypes.func,
  setShortConceptCardId: PropTypes.func,
  isSingleCellAllowed: PropTypes.bool,
  isGeneBelongsToHuman: PropTypes.bool,
  hpmTissues: PropTypes.instanceOf(Array),
  ccleExpressionData: PropTypes.instanceOf(Array),
  ccleExpressionSelectedSortingOption: PropTypes.instanceOf(Object),
  ccleProteomicExpressionsData: PropTypes.instanceOf(Array),
  ccleProteomicsExpressionSelectedSortingOption: PropTypes.instanceOf(Object),
  ccleProteomicSelectedUniprot: PropTypes.string,
  ccleProteomicsAllUniprotOptions: PropTypes.instanceOf(Array),
  sangerCellModelExpressionsData: PropTypes.instanceOf(Array),
  sangerCellModelSelectedUniprot: PropTypes.string,
  sangerCellModelAllUniprotOptions: PropTypes.instanceOf(Array),
  sangerCellModelExpressionSelectedSortingOption: PropTypes.instanceOf(Object),
  gtexExpressionData: PropTypes.instanceOf(Array),
  gtexExpressionSelectedSortingOption: PropTypes.instanceOf(Object),
  tcgaExpressionData: PropTypes.instanceOf(Array),
  healthCellData: PropTypes.instanceOf(Object),
  setSelectedSortingOption: PropTypes.func,
  tcgaExpressionSelectedSortingOption: PropTypes.instanceOf(Object),
  resetGeneExpressionData: PropTypes.func,
  getSamplesDataForCsvExport: PropTypes.func,
  csvDownloadedSamplesLoading: PropTypes.instanceOf(Object),
  setSelectedUniprotOption: PropTypes.func,
  setSelectedProteinExpressionOption: PropTypes.func,
  sangerCellModelExpressionSelectedProteinOption: PropTypes.instanceOf(Object),
  cptacLoading: PropTypes.bool,
  cptacData: PropTypes.instanceOf(Array),
  allCptacData: PropTypes.instanceOf(Array),
  cptacExpressionSelectedSortingOption: PropTypes.instanceOf(Object),
  toggleCellTypeClusterModal: PropTypes.func,
};

class GeneExpressionChapter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfoText: {
        MAIN_TEXT: false,
        CCLE: false,
        GTEX: false,
        CCLE_PROTEOMICS: false,
        SANGER_CELL_MODEL: false,
        CPTAC: false,
        HPM_TISSUES: false,
        HPA: false,
      },
      CCLE: {
        showInfoText: false,
        selectedCells: [],
        dataTransform: 'linear',
        tissuesSelectionMode: false,
      },
      CCLE_PROTEOMICS: {
        showInfoText: false,
        selectedCells: [],
        dataTransform: 'linear',
        tissuesSelectionMode: false,
      },
      SANGER_CELL_MODEL: {
        showInfoText: false,
        selectedCells: [],
        dataTransform: 'linear',
        tissuesSelectionMode: false,
      },
      CPTAC: {
        showInfoText: false,
        selectedCells: [],
        dataTransform: 'linear',
        tissuesSelectionMode: false,
      },
      GTEX: {
        showInfoText: false,
        selectedCells: [],
        dataTransform: 'linear',
        tissuesSelectionMode: false,
      },
      CELL_TYPE: {
        showInfoText: false,
        selectedCells: [],
        dataTransform: 'linear',
        tissuesSelectionMode: false,
      },
      TCGA: {
        showInfoText: false,
        selectedCells: [],
        dataTransform: 'linear',
        tissuesSelectionMode: false,
      },
      HPM_TISSUES: {
        showInfoText: false,
      },
      HPA_PER_CELL_TYPE: {
        showInfoText: false,
        selectedCells: [],
        dataTransform: 'linear',
        tissuesSelectionMode: false,
      },
      HPA_PER_TISSUES: {
        showInfoText: false,
        selectedCells: [],
        dataTransform: 'linear',
        tissuesSelectionMode: false,
      },
      selectedDataset: {},
      samplesPopup: {
        isModalOpen: false,
        tissueGroupName: '',
        tissueCellLineName: '',
        conceptId: '',
        type: '',
        data: null,
        uniprotId: '',
        proteinExpression: '',
        sampleGroupName: '',
      },
    };

    this.initPage();
  }

  initPage = () => {
    const {
      gene: id,
      itsNewConcept,
      initSectionData,
      loadNextChapter,
      tcgaExpressionData,
      ccleExpressionData,
      gtexExpressionData,
      resetGeneExpressionData,
      healthCellData: { healthCellExpressionChartData },
    } = this.props;

    const isSectionEmpty = !(ccleExpressionData.length || gtexExpressionData.length || tcgaExpressionData.length || healthCellExpressionChartData.length);

    if (itsNewConcept || isSectionEmpty) {
      resetGeneExpressionData();
      initSectionData({ id });
    } else {
      loadNextChapter('condition-of-dysregulation');
    }
  };

  toggleInfoText = (tissuesGroup) => {
    this.setState({
      showInfoText: {
        ...this.state.showInfoText,
        [tissuesGroup]: !this.state.showInfoText[tissuesGroup],
      },
    });
  };

  switchOpeningCellLineModal = (e, concept, type) => {
    const { isModalOpen } = this.state.samplesPopup;

    if (!isModalOpen) {
      const { dataTransform } = this.state[type];
      const isSamplesDataExist = getIsSamplesDataExist(type);
      const { key: measureKey } = this.getSampleMeasure(type);
      const sampleGroupName = this.getSampleGroupName(type);
      const tissueGroupName = isSamplesDataExist ? e : concept.group;
      const data = isSamplesDataExist && this.generateDataForSamplePopup(type, concept, tissueGroupName, measureKey);

      this.setState({
        samplesPopup: {
          ...this.state.samplesPopup,
          tissueGroupName,
          tissueCellLineName: e,
          isModalOpen: true,
          conceptId: concept.concept?.id,
          data,
          dataTransform,
          type,
          sampleGroupName,
          uniprotId: this.getUniprotId(type),
          proteinExpression: this.getProteinExpression(type),
        },
      });
    } else {
      this.setState({
        samplesPopup: {
          ...this.state.samplesPopup,
          isModalOpen: false,
          tissueGroupName: '',
          conceptId: '',
          tissueCellLineName: '',
          data: null,
          type: '',
          uniprotId: '',
          proteinExpression: '',
          sampleGroupName: '',
        },
      });
    }
  };

  changeGraphTransform = (e, key, stateParam) => {
    this.setState({
      [key]: {
        ...this.state[key],
        [stateParam]: e.target.value,
      },
    });
  };

  findSelectedTissues = (tissuesGroup, selectedCells, element) => {
    if (tissuesGroup === graphTypes.CPTAC) {
      if (selectedCells.find(c => c.concept === element.concept)) {
        return selectedCells.filter(c => c.concept !== element.concept);
      }
    } else if (tissuesGroup === graphTypes.HPA_PER_CELL_TYPE || tissuesGroup === graphTypes.HPA_PER_TISSUES) {
      if (selectedCells.find(c => c.name === element.name)) {
        return selectedCells.filter(c => c.name !== element.name);
      }
    } else {
      if (selectedCells.find(c => c.concept.name === element.concept.name)) {
        return selectedCells.filter(c => c.concept.name !== element.concept.name);
      }
    }

    return [...selectedCells, element];
  };

  setSelectedTissues = (element, tissuesGroup) => {
    const selectedCells = this.findSelectedTissues(tissuesGroup, this.state[tissuesGroup].selectedCells, element);
    this.setState({
      [tissuesGroup]: {
        ...this.state[tissuesGroup],
        selectedCells,
      },
    });
  };

  clearSelectedTissues = (tissuesGroup) => {
    this.setState({
      [tissuesGroup]: {
        ...this.state[tissuesGroup],
        selectedCells: [],
      },
    });
  };

  unsetTissuesSelectionMode = (tissuesGroup) => {
    this.setState({
      [tissuesGroup]: {
        ...this.state[tissuesGroup],
        selectedCells: [],
        tissuesSelectionMode: false,
      },
    });
  };

  setTissuesSelectionMode = (tissuesGroup) => {
    this.setState({
      [tissuesGroup]: {
        ...this.state[tissuesGroup],
        tissuesSelectionMode: true,
      },
    });
  };

  getCellTypeInitialValues = () => {
    const { healthCellData: { cellTypeOptions } } = this.props;
    if (!cellTypeOptions || !cellTypeOptions.length) {
      return null;
    }
    const cellTypes = cellTypeOptions.find(item => item.type === 'Monocytes and macrophages') ?
      'Monocytes and macrophages' :
      cellTypeOptions[0].type;
    return { cellTypes };
  };

  handleActiveDataset = (option) => {
    this.setState({
      selectedDataset: option,
    });
  };

  getSampleMeasure = (type) => {
    const { sangerCellModelExpressionSelectedProteinOption } = this.props;
    switch (type) {
      case graphTypes.CCLE:
        return {
          label: 'TPM',
          key: 'score'
        };
      case graphTypes.CCLE_PROTEOMICS:
        return {
          label: 'Protein expression',
          key: 'score'
        };
      case graphTypes.HPA_PER_TISSUES:
        return {
          label: 'nTPM',
          key: 'ntpm',
        };
      case graphTypes.SANGER_CELL_MODEL:
        return {
          label: sangerCellModelExpressionSelectedProteinOption.label,
          key: sangerCellModelExpressionSelectedProteinOption.value,
        };
      default:
        return {
          label: 'TPM value',
          key: 'measure',
        };
    }
  };

  getSampleName = (type) => {
    switch (type) {
      case graphTypes.SANGER_CELL_MODEL:
      case graphTypes.CCLE_PROTEOMICS:
      case graphTypes.CCLE:
        return {
          label: 'Sample identifier',
          key: 'name',
        };
      case graphTypes.HPA_PER_TISSUES:
        return {
          label: 'Cell Type Cluster',
          key: 'cellTypeCluster',
        };
      default:
        return {
          label: 'Sample identifier',
          key: 'sample',
        };
    }
  };

  getSampleGroupName = (type) => {
    if (type === graphTypes.HPA_PER_TISSUES) return 'cellTypeGroup';
  };

  getUniprotId = (type) => {
    const { ccleProteomicSelectedUniprot, sangerCellModelSelectedUniprot } = this.props;
    switch (type) {
      case graphTypes.SANGER_CELL_MODEL:
        return sangerCellModelSelectedUniprot;
      case graphTypes.CCLE_PROTEOMICS:
        return ccleProteomicSelectedUniprot;

      default:
        return '';
    }
  };

  getProteinExpression = (type) => {
    const { sangerCellModelExpressionSelectedProteinOption } = this.props;
    switch (type) {
      case graphTypes.SANGER_CELL_MODEL:
        return sangerCellModelExpressionSelectedProteinOption.label;
      default:
        return '';
    }
  };

  getYAxisText = (type) => {
    const { sangerCellModelExpressionSelectedProteinOption } = this.props;
    if (type === graphTypes.CCLE_PROTEOMICS){
      return 'Protein expression';
    }
    if (type === graphTypes.SANGER_CELL_MODEL) {
      if (sangerCellModelExpressionSelectedProteinOption.value === proteinExpressionSortingOptions[0].value) {
        return 'log2 protein intensity';
      }
      return 'Z-scores';
    }
    if (type === graphTypes.HPA_PER_TISSUES) {
      return 'nTPM';
    }
    return '';
  };

  generateDataForSamplePopup = (type, concept, tissueName, measureKey) => {
    if (type === graphTypes.CPTAC) {
      const { allCptacData } = this.props;
      const selectedData = allCptacData.filter(i => i.tumorName === tissueName);

      return selectedData.reduce((acc, i) => {
        if (!acc.find(s => s.idx === i.idx)) {
          const idxPairData = selectedData.filter(x => x.idx === i.idx);
          acc.push({
            idx: i.idx,
            'Normal/Healthy Tissue': idxPairData.find(x => x.tissueType === 'Normal/Healthy Tissue')?.value || 0,
            'Tumor Tissue': idxPairData.find(x => x.tissueType === 'Tumor Tissue')?.value || 0,
          });
        }
        return acc;
      }, []);
    } else {
      return scaleTransformData(this.state[type].dataTransform, concept.samplesData, measureKey);
    }
  };

  handleSampleClick = (type) => {
    if (type === graphTypes.HPA_PER_TISSUES) {
      return this.props.toggleCellTypeClusterModal;
    }
    return false;
  };

  render() {
    const {
      hpmTissues,
      ccleExpressionData,
      ccleExpressionSelectedSortingOption,
      ccleProteomicExpressionsData,
      ccleProteomicSelectedUniprot,
      ccleProteomicsAllUniprotOptions,
      ccleProteomicsExpressionSelectedSortingOption,
      sangerCellModelExpressionsData,
      sangerCellModelSelectedUniprot,
      sangerCellModelAllUniprotOptions,
      sangerCellModelExpressionSelectedSortingOption,
      gtexExpressionData,
      gtexExpressionSelectedSortingOption,
      tcgaExpressionData,
      healthCellData: {
        cellTypeOptions,
        healthCellExpression,
        healthCellExpressionChartData,
      },
      loading,
      error,
      gene: id,
      geneName,
      showTooltip,
      hideTooltip,
      setShortConceptCardId,
      isSingleCellAllowed,
      isGeneBelongsToHuman,
      setSelectedSortingOption,
      setSelectedUniprotOption,
      tcgaExpressionSelectedSortingOption,
      getSamplesDataForCsvExport,
      csvDownloadedSamplesLoading,
      sangerCellModelExpressionSelectedProteinOption,
      setSelectedProteinExpressionOption,
      cptacData,
      cptacLoading,
      cptacExpressionSelectedSortingOption,
    } = this.props;

    const {
      showInfoText,
      CCLE,
      GTEX,
      TCGA,
      CPTAC,
      CELL_TYPE,
      CCLE_PROTEOMICS,
      SANGER_CELL_MODEL,
      HPA_PER_CELL_TYPE,
      HPA_PER_TISSUES,
      samplesPopup,
    } = this.state;

    const sortOptionsWithoutPairedOption = sortingOptions.slice(0, -1);

    return (
      <div id="gene-expression">
        <div className="gene-details-section-title title2">
          <span>Gene Expression</span>
          <button
            className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
            onClick={() => this.toggleInfoText('MAIN_TEXT')}
          />
        </div>
        {
          showInfoText.MAIN_TEXT &&
          <div className="gene-details-info__main">
            The Gene Expression section provides the expression of the gene in normal tissue (both RNA and protein abundance) as well as cancer tissue (from The Cancer Genome Atlas).
          </div>
        }
        {
          <div className="gene-details-section-body">
            <div
              id="bulk-rna-expression"
            >
              <div
                id="bulk-rna-expression__gtex"
                className="gene-expression-block-diagram"
              >
                <span className="gene-expression-block-title-main">RNAseq from GTEx </span>
                <span className="gene-expression-block-title-sub">
                (showing {gtexExpressionData && gtexExpressionData.length} tissues)
                </span>
                <button
                  className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
                  onClick={() => this.toggleInfoText('GTEX')}
                />
                {
                  showInfoText.GTEX &&
                  <div className="gene-details-info__main gene-details-info__main_light">
                    This section provides RNA expression levels (in TPM) in healthy tissues, based on RNAseq data from
                    <a className="link" href="https://gtexportal.org/home/" target="blank"> GTEx</a>.
                    Hover over the bar to view the expression value.
                  </div>
                }
                {
                  loading &&
                  <div className="row text-center">
                    <Spinner isLoading={loading} />
                  </div>
                }
                {
                  gtexExpressionData && gtexExpressionData.length > 0 &&
                    <div className="gene-expression-diagram">
                      <ViolinChart
                        chartTitle={getChartTitle(graphTypes.GTEX, geneName)}
                        chartName={getChartName(graphTypes.GTEX)}
                        chartWrapId="gtex-chart"
                        type={graphTypes.GTEX}
                        geneId={id}
                        geneName={geneName}
                        showExpressionSortSelector={true}
                        sortingOptions={sortOptionsWithoutPairedOption}
                        setSelectedSortingOption={setSelectedSortingOption}
                        selectedSortingOption={gtexExpressionSelectedSortingOption}
                        chartData={gtexExpressionData}
                        isNeededTransform={true}
                        isHiddenExport={false}
                        switchOpenModal={this.switchOpeningCellLineModal}
                        changeViolinTransform={e => this.changeGraphTransform(e, graphTypes.GTEX, 'dataTransform')}
                        violinPlotTransform={GTEX.dataTransform}
                        showTooltip={showTooltip}
                        hideTooltip={hideTooltip}
                        setShortConceptCardId={setShortConceptCardId}
                        selectedCells={GTEX.selectedCells}
                        setSelectedCells={e => this.setSelectedTissues(e, graphTypes.GTEX)}
                        unsetTissuesSelectionMode={() => this.unsetTissuesSelectionMode(graphTypes.GTEX)}
                        tissuesSelectionMode={GTEX.tissuesSelectionMode}
                        setTissuesSelectionMode={() => this.setTissuesSelectionMode(graphTypes.GTEX)}
                        getSamplesDataForCsvExport={getSamplesDataForCsvExport}
                        csvName={getCsvName(graphTypes.GTEX, geneName)}
                        csvDownloadedSamplesLoading={csvDownloadedSamplesLoading.GTEX}
                        isCsvDownloadingAllowed={true}
                        sampleMeasure={this.getSampleMeasure(graphTypes.GTEX)}
                        sampleName={this.getSampleName(graphTypes.GTEX)}
                      />
                    </div>
                }
                <NoData
                  show={!loading && gtexExpressionData && gtexExpressionData.length === 0}
                  customClassName="therapeutic-candidates__no-data therapeutic-candidates__no-data_block"
                />
              </div>

              <div
                id="bulk-rna-expression__tcga"
                className="gene-expression-block-diagram gene-expression-block-diagram_boxplot"
              >
                <span className="gene-expression-block-title-main">
                RNA expression in tumor and healthy tissue from TCGA
                </span>
                <button
                  className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
                  onClick={() => this.toggleInfoText('TCGA')}
                />
                {
                  showInfoText.TCGA &&
                  <div className="gene-details-info__main gene-details-info__main_light">
                    This section provides RNA expression levels (in TPM) in 30 healthy tissues (in green) and 34 tumor types (in red),
                    based on RNAseq data from <a className="link" href="https://cancergenome.nih.gov/" target="blank">The Cancer Genome Atlas</a>.
                    The expression levels are shown as box plots showing the distribution of expression levels over the number of samples that were tested.
                    Hover over the box plot to view the minimum, maximum and quartile values. Use the scroll bar and swipe to the right to view all results.
                  </div>
                }
                <Spinner
                  isLoading={loading}
                  containerClassName="row text-center"
                />
                {
                  tcgaExpressionData && tcgaExpressionData.length > 0 &&
                    <div className="gene-expression-diagram">
                      <ViolinChart
                        chartTitle={getChartTitle(graphTypes.TCGA, geneName)}
                        chartName={getChartName(graphTypes.TCGA)}
                        chartWrapId="tcga-chart"
                        type={graphTypes.TCGA}
                        geneId={id}
                        geneName={geneName}
                        chartData={tcgaExpressionData}
                        isNeededTransform={true}
                        isHiddenExport={false}
                        switchOpenModal={this.switchOpeningCellLineModal}
                        violinPlotTransform={TCGA.dataTransform}
                        changeViolinTransform={e => this.changeGraphTransform(e, 'TCGA', 'dataTransform')}
                        showTooltip={showTooltip}
                        hideTooltip={hideTooltip}
                        setShortConceptCardId={setShortConceptCardId}
                        showLegend={true}
                        showExpressionSortSelector={true}
                        setSelectedSortingOption={setSelectedSortingOption}
                        selectedSortingOption={tcgaExpressionSelectedSortingOption}
                        sortingOptions={sortingOptions}
                        legendsCustoms={{
                          params: [
                            { name: 'Normal Tissues' },
                            { name: 'Tumor Tissues' },
                          ],
                          colors: [
                            selectedTissuesColors.NORMAL_TISSUES[0],
                            selectedTissuesColors.TUMOR_TISSUES[0],
                          ],
                        }}
                        selectedCells={TCGA.selectedCells}
                        setSelectedCells={e => this.setSelectedTissues(e, graphTypes.TCGA)}
                        unsetTissuesSelectionMode={() => this.unsetTissuesSelectionMode(graphTypes.TCGA)}
                        tissuesSelectionMode={TCGA.tissuesSelectionMode}
                        setTissuesSelectionMode={() => this.setTissuesSelectionMode(graphTypes.TCGA)}
                        getSamplesDataForCsvExport={getSamplesDataForCsvExport}
                        csvName={getCsvName(graphTypes.TCGA, geneName)}
                        csvDownloadedSamplesLoading={csvDownloadedSamplesLoading.TCGA}
                        isCsvDownloadingAllowed={true}
                        sampleMeasure={this.getSampleMeasure(graphTypes.TCGA)}
                        sampleName={this.getSampleName(graphTypes.TCGA)}
                      />
                    </div>
                }
                <NoData
                  show={!loading && tcgaExpressionData && tcgaExpressionData.length === 0}
                  customClassName="therapeutic-candidates__no-data therapeutic-candidates__no-data_block"
                />
              </div>

              <div
                id="bulk-rna-expression__ccle"
                className="gene-expression-block-diagram gene-expression-block-diagram_boxplot"
              >
                <span className="gene-expression-block-title-main">RNA expression in cancer cell lines from CCLE</span>
                <button
                  className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
                  onClick={() => this.toggleInfoText('CCLE')}
                />
                {
                  showInfoText.CCLE &&
                  <div className="gene-details-info__main gene-details-info__main_light">
                    The CCLE (<a className="link" href="https://portals.broadinstitute.org/ccle" target="blank">Cancer Cell Line Encyclopedia</a>) project provides a detailed genetic and pharmacologic characterization of a large panel of human cancer models.  The CCLE provides public access to genomic data, analysis and visualization for over 1100 cell lines.
                    Click on the cancer type label to find detailed expression profiles per cell line for the selected cancer type.
                  </div>
                }
                <Spinner
                  isLoading={loading}
                  containerClassName="row text-center"
                />
                {
                  ccleExpressionData && ccleExpressionData.length > 0 &&
                  <div className="gene-expression-diagram">
                    <ViolinChart
                      chartTitle={getChartTitle(graphTypes.CCLE, geneName)}
                      chartName={getChartName(graphTypes.CCLE)}
                      chartWrapId="ccle-chart"
                      type={graphTypes.CCLE}
                      geneId={id}
                      geneName={geneName}
                      showExpressionSortSelector={true}
                      sortingOptions={sortOptionsWithoutPairedOption}
                      setSelectedSortingOption={setSelectedSortingOption}
                      selectedSortingOption={ccleExpressionSelectedSortingOption}
                      chartData={ccleExpressionData}
                      isNeededTransform={true}
                      isHiddenExport={false}
                      switchOpenModal={this.switchOpeningCellLineModal}
                      violinPlotTransform={CCLE.dataTransform}
                      changeViolinTransform={e => this.changeGraphTransform(e, graphTypes.CCLE, 'dataTransform')}
                      showTooltip={showTooltip}
                      hideTooltip={hideTooltip}
                      setShortConceptCardId={setShortConceptCardId}
                      selectedCells={CCLE.selectedCells}
                      setSelectedCells={e => this.setSelectedTissues(e, graphTypes.CCLE)}
                      unsetTissuesSelectionMode={() => this.unsetTissuesSelectionMode(graphTypes.CCLE)}
                      tissuesSelectionMode={CCLE.tissuesSelectionMode}
                      setTissuesSelectionMode={() => this.setTissuesSelectionMode(graphTypes.CCLE)}
                      getSamplesDataForCsvExport={getSamplesDataForCsvExport}
                      csvName={getCsvName(graphTypes.CCLE, geneName)}
                      csvDownloadedSamplesLoading={csvDownloadedSamplesLoading.CCLE}
                      isCsvDownloadingAllowed={true}
                      sampleMeasure={this.getSampleMeasure(graphTypes.CCLE)}
                      sampleName={this.getSampleName(graphTypes.CCLE)}
                    />
                  </div>
                }
                <NoData
                  show={!loading && ccleExpressionData && ccleExpressionData.length === 0}
                  customClassName="therapeutic-candidates__no-data therapeutic-candidates__no-data_block"
                />
              </div>
              <CellTypeExpressionChart
                id="healthCell-chart"
                type={graphTypes.CELL_TYPE}
                geneId={id}
                data={healthCellExpression}
                chartData={healthCellExpressionChartData}
                loading={loading}
                options={cellTypeOptions}
                switchOpenModal={this.switchOpeningCellLineModal}
                beesWarmTransform={CELL_TYPE.dataTransform}
                changeBeesWarmTransform={e => this.changeGraphTransform(e, graphTypes.CELL_TYPE, 'dataTransform')}
                initialValues={this.getCellTypeInitialValues()}
                geneName={geneName}
                showTooltip={showTooltip}
                hideTooltip={hideTooltip}
                setShortConceptCardId={setShortConceptCardId}
                selectedCells={CELL_TYPE.selectedCells}
                showInfoText={showInfoText.CELL_TYPE}
                toggleInfoText={this.toggleInfoText}
                setSelectedCells={e => this.setSelectedTissues(e, graphTypes.CELL_TYPE)}
                unsetTissuesSelectionMode={() => this.unsetTissuesSelectionMode(graphTypes.CELL_TYPE)}
                clearSelectedTissues={() => this.clearSelectedTissues(graphTypes.CELL_TYPE)}
                tissuesSelectionMode={CELL_TYPE.tissuesSelectionMode}
                setTissuesSelectionMode={() => this.setTissuesSelectionMode(graphTypes.CELL_TYPE)}
                getSamplesDataForCsvExport={getSamplesDataForCsvExport}
                csvName={getCsvName(graphTypes.CELL_TYPE, geneName)}
                csvDownloadedSamplesLoading={csvDownloadedSamplesLoading.CELL_TYPE}
              />
            </div>
            <div
              id="single-cell-rna"
            >
              <div
                id="single-cell-rna__hpa"
                className="gene-expression-block-diagram gene-expression-block-diagram_boxplot"
              >
                <span className="gene-expression-block-title-main">Single cell type expression from Human Protein Atlas</span>
                <button
                  className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
                  onClick={() => this.toggleInfoText('HUMAN_PROTEIN_ATLAS')}
                />
                {
                  showInfoText.HUMAN_PROTEIN_ATLAS &&
                  <div className="gene-details-info__main gene-details-info__main_light">
                    This section contains Single Cell Type data from the Human Protein Atlas (HPA) based on single cell
                    RNA sequencing (scRNAseq) data from 29 human tissues and peripheral blood mononuclear cells (PBMCs).
                    These datasets were respectively retrieved from the
                    <a className="link" href="https://www.ebi.ac.uk/gxa/sc/home" target="_blank" rel="noreferrer"> Single Cell Expression Atlas</a>,
                    the <a className="link" href="https://www.humancellatlas.org/" target="_blank" rel="noreferrer"> Human Cell Atlas</a>, the
                    <a className="link" href="https://www.ncbi.nlm.nih.gov/geo/" target="_blank" rel="noreferrer"> Gene Expression Omnibus</a>, the
                    <a className="link" href="https://portal.brain-map.org/" target="_blank" rel="noreferrer"> Allen Brain Map</a>,
                    <a className="link" href="https://ega-archive.org/" target="_blank" rel="noreferrer"> European Genome-phenome Archive </a>
                    and the <a className="link" href="https://tabula-sapiens-portal.ds.czbiohub.org/" target="_blank" rel="noreferrer"> Tabula Sapiens</a>.
                    The complete list of references can be found here:
                    (<a className="link" href="https://www.proteinatlas.org/about/assays+annotation#singlecell_rna" target="_blank" rel="noreferrer">
                    Assays and annotation - The Human Protein Atlas</a>).
                    The scRNAseq analysis comprises all protein-coding genes in 536 individual cell type Clusters which
                    were manually annotated based on an extensive survey of &gt;500 well-known tissue and cell type-specific markers,
                    including both markers from the original publications, and additional markers used in pathology diagnostics.
                    For each cluster, one main cell type was chosen by taking into consideration the expression of different markers.
                    For a few clusters, no main cell type could be selected, and these clusters were not used for gene classification.
                    These clusters are corresponding to 82 cell types and 16 different cell type groups. Normalized transcript
                    expression values, denoted nTPM, were calculated as described here:
                    <a className="link" href="https://www.proteinatlas.org/about/assays+annotation#singlecell_rna" target="_blank" rel="noreferrer">
                      Assays and annotation - The Human Protein Atlas</a>.
                  </div>
                }
                <HpaCharts
                  geneName={geneName}
                  hpaPerCellTypeCustoms={HPA_PER_CELL_TYPE}
                  hpaPerTissuesCustoms={HPA_PER_TISSUES}
                  switchOpenModal={this.switchOpeningCellLineModal}
                  changeGraphTransform={this.changeGraphTransform}
                  setSelectedCells={this.setSelectedTissues}
                  unsetTissuesSelectionMode={this.unsetTissuesSelectionMode}
                  setTissuesSelectionMode={this.setTissuesSelectionMode}
                />
              </div>
              {
                isSingleCellAllowed &&
                isGeneBelongsToHuman &&
                <div
                  id="single-cell-rna__cell-study-viewer"
                  className="gene-details-info__main"
                >
                  <span className="gene-expression-block-title-main">Single cell study viewer</span>
                  <div className="gene-details-info__dataset-block">
                    <DatasetsSelector
                      selectedDataset={this.state.selectedDataset}
                      handleActiveDataset={this.handleActiveDataset}
                    />
                    <Button
                      target="_blank"
                      href={`/workflows/cell-study?geneId=${id}&geneName=${geneName}&tumorType=${this.state.selectedDataset.tumorType}`}
                      customClassName="button button-light"
                      text="Open"
                    />
                  </div>
                </div>
              }
            </div>
            <div id="protein-expression">
              <div
                id="protein-expression__hpm"
                className="gene-expression-block-diagram"
              >
                <span className="gene-expression-block-title-main">
                Protein abundance measurement from Human Proteome Map
                </span>
                <span className="gene-expression-block-title-sub">
                (showing {hpmTissues.length} tissues)
                </span>
                <button
                  className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
                  onClick={() => this.toggleInfoText('HPM_TISSUES')}
                />
                {
                  showInfoText.HPM_TISSUES &&
                  <div className="gene-details-info__main gene-details-info__main_light">
                    This section provides Protein Abundance levels in healthy tissues, from the
                    <a className="link" href="http://www.humanproteomemap.org/" target="blank"> Human Proteome Map</a>.
                    Hover over the bar to view the expression value.
                  </div>
                }
                <Spinner
                  isLoading={loading}
                  containerClassName="row text-center"
                />
                {
                  hpmTissues.length > 0 ?
                    <div className="gene-expression-diagram">
                      <BarChartDiagram
                        source="protein-abundance-from-hpm-diagram"
                        data={hpmTissues}
                        dataKey="measure"
                        rotateXAxis={true}
                        color={{ positive: PURPLE_COLOR, negative: PURPLE_COLOR }}
                        geneName={geneName}
                        chartName="Protein_abundance_measurement_from_Human_Protein_Map"
                        chartTitle="Protein abundance measurement from Human Protein Map"
                        showTooltip={showTooltip}
                        hideTooltip={hideTooltip}
                        setShortConceptCardId={setShortConceptCardId}
                      />
                    </div> :
                    <NoData
                      show={!loading && hpmTissues && hpmTissues.length === 0}
                      customClassName="therapeutic-candidates__no-data therapeutic-candidates__no-data_block"
                    />
                }
              </div>
              <div
                id="protein-expression__ccle"
                className="gene-expression-block-diagram gene-expression-block-diagram_boxplot"
              >
                <span className="gene-expression-block-title-main">Protein expression in cancer cell lines from CCLE</span>
                <button
                  className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
                  onClick={() => this.toggleInfoText('CCLE_PROTEOMICS')}
                />
                {
                  showInfoText.CCLE_PROTEOMICS &&
                  <div className="gene-details-info__main gene-details-info__main_light">
                    The CCLE (<a className="link" href="https://sites.broadinstitute.org/ccle" target="blank">Cancer Cell Line Encyclopedia</a>)
                    project provides a detailed genetic and pharmacologic characterization of a large panel of human cancer models.
                    The CCLE provides public access to proteomics data for 375 cell Lines. As genes can encode for
                    multiple proteins the dropdown will allow to visualise the expression of each protein separately by
                    selecting the corresponding Uniprot Accession. Click on the cancer type label to find detailed protein
                    expression per cell line for the selected cancer type. Please note that the protein expression is
                    normalised to pooled (bridge) samples and can therefore also contain negative values. For more
                    information on the normalisation please read the methods section in the <a className="link" href="https://pubmed.ncbi.nlm.nih.gov/31978347/" target="blank">original publication</a>.
                    Furthermore, in some cases there are technical duplicates. In case of technical duplicates both
                    values are plotted. Hovering over the plot will show basic statistics including “the number of
                    samples with expression” as well as “the total number of cell lines”. Note that when there are
                    technical duplicates the number of samples with expression can be higher than the number of cell lines.
                    When the number of samples with expression is lower than the number of cell lines it means that not
                    all cell lines have protein expression.
                  </div>
                }
                <Spinner
                  isLoading={loading}
                  containerClassName="row text-center"
                />
                {
                  ccleProteomicExpressionsData && ccleProteomicExpressionsData.length > 0 &&
                  <div className="gene-expression-diagram">
                    <ViolinChart
                      chartTitle={getChartTitle(graphTypes.CCLE_PROTEOMICS, geneName)}
                      chartName={getChartName(graphTypes.CCLE_PROTEOMICS)}
                      chartWrapId="ccle-proteomics-chart"
                      type={graphTypes.CCLE_PROTEOMICS}
                      geneId={id}
                      yAxisText={this.getYAxisText(graphTypes.CCLE_PROTEOMICS)}
                      geneName={geneName}
                      showExpressionSortSelector={true}
                      setSelectedSortingOption={setSelectedSortingOption}
                      selectedSortingOption={ccleProteomicsExpressionSelectedSortingOption}
                      sortingOptions={sortOptionsWithoutPairedOption}
                      chartData={ccleProteomicExpressionsData}
                      isNeededTransform={false}
                      isHiddenExport={false}
                      switchOpenModal={this.switchOpeningCellLineModal}
                      violinPlotTransform={CCLE_PROTEOMICS.dataTransform}
                      changeViolinTransform={e => this.changeGraphTransform(e, graphTypes.CCLE_PROTEOMICS, 'dataTransform')}
                      showTooltip={showTooltip}
                      hideTooltip={hideTooltip}
                      setShortConceptCardId={setShortConceptCardId}
                      selectedCells={CCLE_PROTEOMICS.selectedCells}
                      setSelectedCells={e => this.setSelectedTissues(e, graphTypes.CCLE_PROTEOMICS)}
                      unsetTissuesSelectionMode={() => this.unsetTissuesSelectionMode(graphTypes.CCLE_PROTEOMICS)}
                      tissuesSelectionMode={CCLE_PROTEOMICS.tissuesSelectionMode}
                      setTissuesSelectionMode={() => this.setTissuesSelectionMode(graphTypes.CCLE_PROTEOMICS)}
                      getSamplesDataForCsvExport={getSamplesDataForCsvExport}
                      csvName={getCsvName(graphTypes.CCLE_PROTEOMICS, geneName, ccleProteomicSelectedUniprot)}
                      csvDownloadedSamplesLoading={csvDownloadedSamplesLoading.CCLE_PROTEOMICS}
                      isCsvDownloadingAllowed={true}
                      showUniprotSelector={true}
                      selectedUniprot={ccleProteomicSelectedUniprot}
                      uniprotOptions={ccleProteomicsAllUniprotOptions}
                      setSelectedUniprotOption={setSelectedUniprotOption}
                      sampleMeasure={this.getSampleMeasure(graphTypes.CCLE_PROTEOMICS)}
                      sampleName={this.getSampleName(graphTypes.CCLE_PROTEOMICS)}
                    />
                  </div>
                }
                <NoData
                  show={!loading && ccleProteomicExpressionsData && ccleProteomicExpressionsData.length === 0}
                  customClassName="therapeutic-candidates__no-data therapeutic-candidates__no-data_block"
                />
              </div>
              <div
                id="protein-expression__sanger-cell-model"
                className="gene-expression-block-diagram gene-expression-block-diagram_boxplot"
              >
                <span className="gene-expression-block-title-main">Protein expression in cancer cell lines from Sanger Cell Model Passports</span>
                <button
                  className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
                  onClick={() => this.toggleInfoText('SANGER_CELL_MODEL')}
                />
                {
                  showInfoText.SANGER_CELL_MODEL &&
                  <div className="gene-details-info__main gene-details-info__main_light">
                    The Sanger <a className="link" href="https://portals.broadinstitute.org/ccle" target="blank">Cell Model Passports </a>
                    provides mass spectometry data of 949 cancer cell lines across 30 tissues and 44 different cancer types.
                    The final dataset, termed ProCan-DepMapSanger, was derived from 6,864 mass spectrometry runs and quantifying a total of 8,498 proteins. This
                    quantification captures evidences of cell-type and post-transcriptional modifications, potentially
                    revealing thousands of cancer vulnerabilities that could not be significant at the transcript level.
                    The mass spectrometry data provides relative rather than absolute protein quantitation. This enables
                    comparisons between the intensity of a particular protein across different cell lines, but it is not
                    valid to compare different protein intensities within a single cell line. Data is log2-transformed
                    (protein_intensity) and Z-Scores (zscore) are calculated with reference to each protein as measured
                    across the entire cell line panel (for more information
                    <a className="link" href="https://depmap.sanger.ac.uk/documentation/datasets/proteomics/" target="blank"> Proteomics - The Cancer Dependency Map at Sanger </a> and
                    <a className="link" href="https://www.cell.com/cancer-cell/fulltext/S1535-6108(22)00274-4#sectitle0030" target="blank"> Pan-cancer proteomic map of 949 human cell lines</a>).
                  </div>
                }
                <Spinner
                  isLoading={loading}
                  containerClassName="row text-center"
                />
                {
                  sangerCellModelExpressionsData && sangerCellModelExpressionsData.length > 0 &&
                  <div className="gene-expression-diagram">
                    <ViolinChart
                      chartTitle={getChartTitle(graphTypes.SANGER_CELL_MODEL, geneName)}
                      chartName={getChartName(graphTypes.SANGER_CELL_MODEL)}
                      chartWrapId="sanger-cell-model-chart"
                      type={graphTypes.SANGER_CELL_MODEL}
                      geneId={id}
                      yAxisText={this.getYAxisText(graphTypes.SANGER_CELL_MODEL)}
                      geneName={geneName}
                      showExpressionSortSelector={true}
                      showProteinExpressionSortSelector={true}
                      setSelectedSortingOption={setSelectedSortingOption}
                      selectedSortingOption={sangerCellModelExpressionSelectedSortingOption}
                      sortingOptions={sortOptionsWithoutPairedOption}
                      chartData={sangerCellModelExpressionsData}
                      isNeededTransform={false}
                      isHiddenExport={false}
                      switchOpenModal={this.switchOpeningCellLineModal}
                      violinPlotTransform={SANGER_CELL_MODEL.dataTransform}
                      changeViolinTransform={e => this.changeGraphTransform(e, graphTypes.SANGER_CELL_MODEL, 'dataTransform')}
                      showTooltip={showTooltip}
                      hideTooltip={hideTooltip}
                      setShortConceptCardId={setShortConceptCardId}
                      selectedCells={SANGER_CELL_MODEL.selectedCells}
                      setSelectedCells={e => this.setSelectedTissues(e, graphTypes.SANGER_CELL_MODEL)}
                      unsetTissuesSelectionMode={() => this.unsetTissuesSelectionMode(graphTypes.SANGER_CELL_MODEL)}
                      tissuesSelectionMode={SANGER_CELL_MODEL.tissuesSelectionMode}
                      setTissuesSelectionMode={() => this.setTissuesSelectionMode(graphTypes.SANGER_CELL_MODEL)}
                      getSamplesDataForCsvExport={getSamplesDataForCsvExport}
                      csvName={getCsvName(graphTypes.SANGER_CELL_MODEL, geneName, sangerCellModelSelectedUniprot, sangerCellModelExpressionSelectedProteinOption.label)}
                      csvDownloadedSamplesLoading={csvDownloadedSamplesLoading.SANGER_CELL_MODEL}
                      isCsvDownloadingAllowed={true}
                      showUniprotSelector={true}
                      selectedUniprot={sangerCellModelSelectedUniprot}
                      uniprotOptions={sangerCellModelAllUniprotOptions}
                      setSelectedUniprotOption={setSelectedUniprotOption}
                      selectedProteinExpression={sangerCellModelExpressionSelectedProteinOption}
                      setSelectedProteinExpressionOption={setSelectedProteinExpressionOption}
                      sampleMeasure={this.getSampleMeasure(graphTypes.SANGER_CELL_MODEL)}
                      sampleName={this.getSampleName(graphTypes.SANGER_CELL_MODEL)}
                    />
                  </div>
                }
                <NoData
                  show={!loading && sangerCellModelExpressionsData && sangerCellModelExpressionsData.length === 0}
                  customClassName="therapeutic-candidates__no-data therapeutic-candidates__no-data_block"
                />
              </div>
              <div
                id="protein-expression__cptac"
                className="gene-expression-block-diagram gene-expression-block-diagram_boxplot"
              >
                <span className="gene-expression-block-title-main">Protein expression in tumor and healthy tissue from CPTAC</span>
                <button
                  className="gene-details-info__btn fa fa-info-circle icon first-info-icon"
                  onClick={() => this.toggleInfoText('CPTAC')}
                />
                {
                  showInfoText.CPTAC &&
                  <div className="gene-details-info__main gene-details-info__main_light">
                    The “Clinical Proteomic Tumor Analysis Consortium” (CPTAC) is a comprehensive and coordinated effort
                    to accelerate the understanding of the molecular basis of cancer through the application of robust,
                    quantitative, proteomic technologies and workflows (<a className="link" href="https://proteomics.cancer.gov/programs/cptac" target="blank">CPTAC</a>).
                    To be able to compare different cancer types CPTAC Pan-Cancer Proteome data has been processed by the
                    University of Michigan team&apos;s pipeline, and then post-processed by the Baylor College of
                    Medicine&apos;s pipeline, where data were normalized across cancer types by median centering of the
                    medians of reference intensities of each cancer type (<a className="link" href="https://pdc.cancer.gov/pdc/cptac-pancancer" target="blank">Proteomic Data Commons </a>
                    file: Proteome_BCM_v34_harmonized_v1.zip; for more information also see the publication:
                    <a className="link" href="https://www.cell.com/cancer-cell/fulltext/S1535-6108(23)00219-2" target="blank"> Proteogenomic data and resources for pan-cancer analysis</a>).
                    Proteomics data is available for 10 cancer types. Click on the violin-plot to find protein expression per sample
                    for the selected cancer type.
                  </div>
                }
                <Spinner
                  isLoading={cptacLoading}
                  containerClassName="row text-center"
                />
                {
                  cptacData && cptacData.length > 0 &&
                  <div className="gene-expression-diagram">
                    <GroupedViolinChart
                      chartName="Expressions_(TPM)_from_CPTAC"
                      chartWrapId="cptac-chart"
                      firstName={cptacTissuesNames.HEALTHY}
                      secondName={cptacTissuesNames.TUMOR}
                      chartData={cptacData}
                      type="CPTAC"
                      chartTitle={getChartTitle(graphTypes.CPTAC, geneName)}
                      showTooltip={showTooltip}
                      hideTooltip={hideTooltip}
                      setShortConceptCardId={setShortConceptCardId}
                      showTitleOnPage={false}
                      isNeededTransform={true}
                      isHiddenExport={false}
                      customVerticalLineX={50}
                      violinPlotTransform={CPTAC.dataTransform}
                      setViolinPlotTransform={e => this.changeGraphTransform(e, graphTypes.CPTAC, 'dataTransform')}
                      yAxisText="Protein abundance"
                      isCsvDownloadingAllowed={true}
                      csvName={getCsvName(graphTypes.CPTAC, geneName)}
                      selectedCells={CPTAC.selectedCells}
                      setSelectedCells={e => this.setSelectedTissues(e, graphTypes.CPTAC)}
                      unsetTissuesSelectionMode={() => this.unsetTissuesSelectionMode(graphTypes.CPTAC)}
                      tissuesSelectionMode={CPTAC.tissuesSelectionMode}
                      setTissuesSelectionMode={() => this.setTissuesSelectionMode(graphTypes.CPTAC)}
                      showExpressionSortSelector={true}
                      setSelectedSortingOption={setSelectedSortingOption}
                      selectedSortingOption={cptacExpressionSelectedSortingOption}
                      sortingOptions={sortOptionsWithoutPairedOption}
                      switchOpenModal={this.switchOpeningCellLineModal}
                    />
                  </div>
                }
                <NoData
                  show={!cptacLoading && cptacData && cptacData.length === 0}
                  customClassName="therapeutic-candidates__no-data therapeutic-candidates__no-data_block"
                />
              </div>
            </div>
          </div>
        }
        {
          samplesPopup.isModalOpen &&
          <ModalComponent
            isOpen={samplesPopup.isModalOpen}
            modalClassName="cell-line-chart-modal"
            closeCb={() => this.switchOpeningCellLineModal()}
          >
            <SamplesChart
              geneId={id}
              geneName={geneName}
              type={samplesPopup.type}
              existedSamplesData={samplesPopup.data}
              relatedId={samplesPopup.conceptId}
              isSamplesDataExist={getIsSamplesDataExist(samplesPopup.type)}
              sangerCellModelExpressionSelectedProteinOption={sangerCellModelExpressionSelectedProteinOption}
              tissueCellLineName={samplesPopup.tissueCellLineName}
              tissueGroupName={samplesPopup.tissueGroupName}
              transform={this.state[samplesPopup.type].dataTransform}
              scale={scaleOptions[this.state[samplesPopup.type].dataTransform].value}
              sampleMeasure={this.getSampleMeasure(samplesPopup.type)}
              sampleName={this.getSampleName(samplesPopup.type)}
              yAxisText={this.getYAxisText(samplesPopup.type)}
              sampleGroupName={samplesPopup.sampleGroupName}
              onSampleClick={this.handleSampleClick(samplesPopup.type)}
              csvName={getCsvName(samplesPopup.type, geneName, samplesPopup.uniprotId, samplesPopup.proteinExpression)}
            />
          </ModalComponent>
        }
        <Error
          show={error}
          customClassName="row text-center error-text"
        />
      </div>
    );
  }
}

GeneExpressionChapter.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    hpmTissues: SELECTORS.getHpmTissues(state),
    ccleExpressionData: SELECTORS.getCcleExpressionDataSelector(state),
    ccleExpressionSelectedSortingOption: SELECTORS.getCcleExpressionSelectedSortingOptionSelector(state),
    ccleProteomicExpressionsData: SELECTORS.getCcleProteomicExpressionsDataSelector(state),
    ccleProteomicSelectedUniprot: SELECTORS.getCcleProteomicsSelectedUniprotSelector(state),
    ccleProteomicsAllUniprotOptions: SELECTORS.getCcleProteomicsAllUniprotOptions(state),
    ccleProteomicsExpressionSelectedSortingOption: SELECTORS.getCcleProteomicsExpressionSelectedSortingOptionSelector(state),
    sangerCellModelExpressionsData: SELECTORS.getSangerCellModelExpressionsDataSelector(state),
    sangerCellModelSelectedUniprot: SELECTORS.getSangerCellModelSelectedUniprotSelector(state),
    sangerCellModelAllUniprotOptions: SELECTORS.getSangerCellModelAllUniprotOptions(state),
    sangerCellModelExpressionSelectedSortingOption: SELECTORS.sangerCellModelExpressionSelectedSortingOptionSelector(state),
    sangerCellModelExpressionSelectedProteinOption: SELECTORS.sangerCellModelExpressionSelectedProteinOptionSelector(state),
    gtexExpressionData: SELECTORS.getGtexExpressionDataSelector(state),
    gtexExpressionSelectedSortingOption: SELECTORS.getGtexExpressionSelectedSortingOptionSelector(state),
    tcgaExpressionData: SELECTORS.getTcgaExpressionDataSelector(state),
    tcgaExpressionSelectedSortingOption: SELECTORS.getTcgaSelectedSortingOption(state),
    healthCellData: SELECTORS.getHealthCellDataInit(state),
    cptacData: SELECTORS.getSortedCptacDataSelector(state),
    allCptacData: SELECTORS.getAllCptacDataSelector(state),
    cptacLoading: SELECTORS.getCptacLoadingSelector(state),
    cptacExpressionSelectedSortingOption: SELECTORS.getCptacExpressionSelectedSortingOptionSelector(state),
    loading: SELECTORS.getLoading(state),
    error: SELECTORS.getError(state),
    isSingleCellAllowed: isSingleCellAllowedSelector(state),
    csvDownloadedSamplesLoading: SELECTORS.getCsvDownloadedSamplesLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initSectionData(data) {
      dispatch(ACTIONS.geneExpressionInitRequest(data));
    },
    loadNextChapter(data) {
      dispatch(loadNextChapterAction(data));
    },
    showTooltip(data) {
      dispatch(showTooltipAction(data));
    },
    hideTooltip() {
      dispatch(hideTooltipAction());
    },
    setShortConceptCardId(data) {
      dispatch(shortConceptCardSetIdAction(data));
    },
    setSelectedSortingOption(data) {
      dispatch(ACTIONS.setSelectedSortingOptionAction(data));
    },
    setSelectedUniprotOption(data) {
      dispatch(ACTIONS.setSelectedUniprotOptionAction(data));
    },
    setSelectedProteinExpressionOption(data) {
      dispatch(ACTIONS.setSelectedProteinExpressionOptionAction(data));
    },
    resetGeneExpressionData() {
      dispatch(ACTIONS.geneExpressionInitReset());
    },
    getSamplesDataForCsvExport(data) {
      dispatch(ACTIONS.getSamplesDataForCsvExportAction(data));
    },
    toggleCellTypeClusterModal(data) {
      dispatch(toggleIsModalOpenAction(data));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneExpressionChapter);
