import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

export const getProjectsManagementDataAction = createAction('@@projects/MANAGEMENT_GET_DATA');
export const setProjectsManagementDataAction = createAction('@@projects/MANAGEMENT_SET_DATA');
export const throwProjectsManagementErrorAction = createAction('@@projects/MANAGEMENT_THROW_ERROR');
export const clearProjectsManagementDataAction = createAction('@@projects/MANAGEMENT_CLEAR');
export const sortProjectsManagementDataAction = createAction('@@projects/MANAGEMENT_SORT');
export const filterProjectsManagementDataAction = createAction('@@projects/MANAGEMENT_FILTER');
export const getProjectDataAction = createAction('@@projects/MANAGEMENT_GET_PROJECT_DATA');
export const setProjectDataAction = createAction('@@projects/MANAGEMENT_SET_PROJECT_DATA');

export const deleteProjectsManagementItemAction = createAction('@@projects/MANAGEMENT_DELETE_PROJECT');
export const editProjectsManagementItemAction = createAction('@@projects/MANAGEMENT_EDIT_PROJECT');
export const createProjectsManagementItemAction = createAction('@@projects/MANAGEMENT_CREATE_PROJECT');
export const throwProjectPopupErrorAction = createAction('@@projects/MANAGEMENT_POPUP_THROW_ERROR');

export const setShowCreateProjectPopupAction = createAction('@@projects/SET_SHOW_CREATE_PROJECT_POPUP');
export const setEditProjectIdPopupAction = createAction('@@projects/SET_EDIT_PROJECT_ID_POPUP');
export const setShowDeleteProjectPopupAction = createAction('@@projects/SET_SHOW_MANAGEMENT_DELETE_PROJECT_POPUP');

export const getUsersByDomainAction = createAction('@@projects/GET_USERS');
export const setUsersByDomainAction = createAction('@@usersProject/SET_USERS');

const initialState = fromJS({
  users: [],
  projects: null,
  projectData: null,
  loading: true,
  error: null,
  popupError: null,
  sorting: { sortBy: 'lastOpened', sortDirection: 'DESC' },
  filters: {
    projectName: '',
    userName: '',
  },
  showDeleteProjectPopup: false,
  showCreateProjectPopup: false,
  editProjectIdPopup: null,
});

const reducer = handleActions(
  {
    [getProjectsManagementDataAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [setProjectsManagementDataAction]: (state, { payload }) =>
      state
        .update('projects', () => fromJS(payload))
        .update('loading', () => false)
        .update('error', () => null),
    [throwProjectsManagementErrorAction]: (state, { payload }) =>
      state
        .update('projects', () => null)
        .update('loading', () => false)
        .update('error', () => payload),
    [sortProjectsManagementDataAction]: (state, { payload }) =>
      state
        .update('sorting', () => fromJS(payload)),
    [filterProjectsManagementDataAction]: (state, { payload }) =>
      state
        .updateIn(['filters', `${payload.key}`], () => fromJS(payload.value)),
    [deleteProjectsManagementItemAction]: (state, { payload }) =>
      state
        .update('projects', data => (
          data.map(item => (
            item.merge(fromJS({ selected: payload }))
          ))
        )),
    [getUsersByDomainAction]: state =>
      state
        .update('loading', () => true)
        .update('error', () => null),
    [setUsersByDomainAction]: (state, { payload }) =>
      state
        .update('users', () => fromJS(payload))
        .update('loading', () => false),
    [setShowDeleteProjectPopupAction]: (state, { payload }) =>
      state
        .update('showDeleteProjectPopup', () => payload),
    [setShowCreateProjectPopupAction]: (state, { payload }) =>
      state
        .update('showCreateProjectPopup', () => fromJS(payload))
        .update('popupError', () => null),
    [setEditProjectIdPopupAction]: (state, { payload }) =>
      state
        .update('editProjectIdPopup', () => fromJS(payload))
        .update('popupError', () => null),
    [setProjectDataAction]: (state, { payload }) =>
      state
        .update('projectData', () => payload),
    [throwProjectPopupErrorAction]: (state, { payload }) =>
      state
        .update('popupError', () => fromJS(payload)),
    [clearProjectsManagementDataAction]: () => initialState,
  },
  initialState
);

export default reducer;
