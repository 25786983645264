import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import ReactTooltip from 'react-tooltip';
// Store
import * as s from '../../store/selectors';
import * as a from '../../store/actions';
// Components
import SelectGene from '../../../../../common/SelectGene/SelectGene';
import SingleCellChartByGene from './Components/SingleCellChartByGene/SingleCellChartByGene';
import SingleCellViolinChart from './Components/SingleCellViolinChart/SingleCellViolinChart';
import Checkbox from '../../../../../common/Inputs/Checkbox/Checkbox';
// Constants
import { graphFilterEnum } from '../../constants';

const propTypes = {
  geneInfo: PropTypes.instanceOf(Object),
  selectedGene: PropTypes.instanceOf(Object),
  setSelectedGene: PropTypes.func,
  filtersAreEmpty: PropTypes.bool,
  selectedFilterValue: PropTypes.instanceOf(Object),
  geneData: PropTypes.instanceOf(Array),
  filteredViolinData: PropTypes.instanceOf(Array),
  measures: PropTypes.instanceOf(Object),
  geneDataError: PropTypes.string,
  geneDataLoading: PropTypes.bool,
  geneDataBackgroundGraphs: PropTypes.instanceOf(Array),
  getGeneData: PropTypes.func,
  datasets: PropTypes.instanceOf(Object),
  filtersData: PropTypes.instanceOf(Object),
  measureExtremes: PropTypes.instanceOf(Array),
  selectedDataset: PropTypes.instanceOf(Object),
};

const SingleCellChartsByGeneData = (props) => {
  const {
    geneInfo,
    selectedGene,
    setSelectedGene,
    filtersAreEmpty,
    selectedFilterValue,
    geneData,
    filteredViolinData,
    measures,
    geneDataError,
    geneDataLoading,
    geneDataBackgroundGraphs,
    getGeneData,
    datasets,
    filtersData,
    measureExtremes,
    selectedDataset,
  } = props;

  const [includeZeros, setIncludeZeros] = useState(true);
  const [splitConcepts, setSplitConcepts] = useState(false);

  function handleGeneSearch(gene) {
    setSelectedGene(gene);
    getGeneData({
      datasets: datasets.ids,
      tumorType: datasets.tumorType,
      geneId: gene.key,
    });
  }

  useEffect(() => {
    if (selectedGene) {
      getGeneData({
        datasets: datasets.ids,
        tumorType: datasets.tumorType,
        geneId: selectedGene.key,
      });
    }
  }, [datasets, filtersData]);

  useEffect(() => {
    if (geneInfo && geneInfo.geneId) {
      handleGeneSearch({ ...geneInfo, key: geneInfo.geneId, value: geneInfo.geneName });
    }
  }, []);

  useEffect(() => {
    setIncludeZeros(true);
    setSplitConcepts(false);
  }, [selectedDataset]);

  const getFilteredViolinData = (data) => {
    if (!data) return [];
    if (splitConcepts) {
      return data.splited;
    }
    return data.base;
  };

  return (
    <div className="single-cell-gene">
      <div className="single-cell-study-dataset">
        <div className="single-cell-study-dataset__filters">
          <span className="single-cell-selector__label">
            Human gene name:
          </span>
          <SelectGene
            geneName={geneInfo && geneInfo.name}
            onSubmit={handleGeneSearch}
          />
        </div>
        {
          selectedGene &&
          <>
            <div className="single-cell-study__titles-block">
              {
                datasets.titles.map((title, idx) => (
                  <h3 key={idx} className="single-cell-study__title title3">{`${title}: ${selectedGene.value}`}</h3>
                ))
              }
            </div>
            <div className="single-cell-study__charts">
              {
                datasets.ids.map((id, idx) => (
                  <SingleCellChartByGene
                    key={id}
                    chartData={geneData[idx]}
                    backgroundGraph={geneDataBackgroundGraphs[idx]}
                    measure={measures[idx]}
                    measureExtremes={measureExtremes[idx]}
                    error={geneDataError}
                    loading={geneDataLoading}
                    noData={!!geneData[idx] && !geneData[idx].length && !measureExtremes[idx]}
                    chartTitle={`Single cell, ${datasets.titles[idx]}: ${selectedGene.value}`}
                    chartName="Single_cell"
                    geneName={selectedGene.value}
                    filtersAreEmpty={filtersAreEmpty}
                    chartId={`single-cell-study-chart-by-gene-${id}`}
                  />
                ))
              }
            </div>
            {
              selectedFilterValue.value === graphFilterEnum.CELL_TYPE &&
                <>
                  <div className="single-cell-study__titles-block">
                    {
                      datasets.titles.map((title, idx) =>
                        (<h3 key={idx} className="single-cell-study__title title3">{`${title}: ${selectedGene.value}`}</h3>))
                    }
                  </div>
                  {
                    !geneDataLoading &&
                    geneData.some(arr => !!arr.length) &&
                    <div className="single-cell-study-violins-options">
                      <div className="single-cell-study-violins-options__item">
                        <Checkbox
                          checked={!includeZeros}
                          onChange={() => setIncludeZeros(!includeZeros)}
                        />
                        <span className="single-cell-study-violins-options__text">
                            exclude 0 values
                        </span>
                      </div>
                      {
                        // TODO: uncomment this piece when ui will be getting splitted data with zero
                        // selectedDataset.splitByCondition.enabled &&
                        // <div
                        //   data-tip={true}
                        //   data-for="splitTumorAndTissue"
                        //   className="single-cell-study-violins-options__item"
                        // >
                        //   <Checkbox
                        //     checked={splitConcepts}
                        //     wrapperClassName="single-cell-study-violins-boxplot__checkbox"
                        //     onChange={() => setSplitConcepts(!splitConcepts)}
                        //   />
                        //   <span className="single-cell-study-violins-options__text">
                        //        split tumor and tissue
                        //   </span>
                        //   <ReactTooltip
                        //     type="light"
                        //     place="bottom"
                        //     id="splitTumorAndTissue"
                        //     className="single-cell-study-violins-options__tooltip"
                        //   >
                        //     { selectedDataset.splitByCondition.hoverText }
                        //   </ReactTooltip>
                        // </div>
                      }
                    </div>
                  }
                  <div className="single-cell-study__charts">
                    {
                      datasets.ids.map((id, idx) => (
                        <SingleCellViolinChart
                          key={id}
                          data={getFilteredViolinData(filteredViolinData && filteredViolinData[idx])}
                          noData={!!geneData[idx] && !geneData[idx].length && !measureExtremes[idx]}
                          error={geneDataError}
                          loading={geneDataLoading}
                          geneName={selectedGene.value}
                          chartTitle={`Single cell, ${datasets.titles[idx]}: ${selectedGene.value}`}
                          chartName="Single_cell_violin"
                          chartId={`single-cell-violin-chart-${id}`}
                          includeZeros={includeZeros}
                        />
                      ))
                    }
                  </div>
                </>
            }
          </>
        }
      </div>
    </div>
  );
};

SingleCellChartsByGeneData.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    measureExtremes: s.getGeneDataMeasureExtremesSelector(state),
    filtersData: s.getDataFiltersSelector(state),
    selectedGene: s.getSelectedGeneSelector(state),
    geneData: s.getGeneDataSelector(state),
    filteredViolinData: s.getFilteredViolinDataSelector(state),
    geneDataError: s.getGeneDataErrorSelector(state),
    geneDataLoading: s.getGeneDataLoadingSelector(state),
    geneDataBackgroundGraphs: s.getGeneDataBackgroundGraphsSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedGene(data) {
      dispatch(a.setSelectedGeneAction(data));
    },
    getGeneData(data) {
      dispatch(a.getGeneDataAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleCellChartsByGeneData);
