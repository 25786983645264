import React from 'react';
// Components
import CellLineSelectionForm from './Components/CellLineSelectionForm/CellLineSelectionForm';
import CellLineSelectionBlock from './Components/CellLineSelectionBlock/CellLineSelectionBlock';
import ResultsExpressionSection from './Components/ResultsExpressionSection/ResultsExpressionSection';
// Styles
import './styles.scss';
import '../../Workflows.scss';

const CellLineSelection = () => {
  return (
    <div className="algorithm">
      <div className="algorithm__wrap">
        <div className="algorithm__container">
          <div className="cell-line-selection-title">
            <h3 className="workflows__title title3">
              Cell Line Selection algorithm
            </h3>
            <p className="workflows__text">
              Rank cancer cell lines based on the expression level of one or more genes. To get started, either type the name of one or two genes or select a gene set from your sets library. Expression data is from CCLE (RNA and protein expression) and Cell Model Passports (protein expression only). Cell lines can be included or excluded from the ranking individually or per tumor type. These selections can be saved and reused as profiles for later rankings.
            </p>
          </div>

          <CellLineSelectionForm />
          <CellLineSelectionBlock />
        </div>
      </div>

      <div className="algorithm__container">
        <ResultsExpressionSection />
      </div>
    </div>
  );
};

export default CellLineSelection;

