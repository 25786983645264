import { call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';

// Api
import Api from '../../../../Api/Api';
// Store
import * as a from './reducer';
import { getProjectFormSelector } from './selectors';
import { getUserId } from '../../../Header/selectors';
import {getProjectsAction} from '../../ProjectsTabs/store/reducer';
import {toastSuccessAction} from '../../../../toast/toastReducer';

function* getProjectsManagementDataWorker() {
  try {
    const { data } = yield call(Api.getAllProjects);
    const expanded = data.map((project) => {
      const { users, owner } = project;
      const allUsers = [...users, owner];
      const uniqueUsers = [...new Set(allUsers)];
      return (
        Object.assign({}, project, {
          usersCount: uniqueUsers.length,
        })
      );
    });
    yield put(a.setProjectsManagementDataAction(expanded));
  } catch (e) {
    yield put(a.throwProjectsManagementErrorAction(e.message));
  }
}

function* deleteProjectsManagementItemWorker({ payload }) {
  try {
    yield call(Api.deleteProject, payload);
    yield put(a.getProjectsManagementDataAction());
    yield put(a.setShowDeleteProjectPopupAction(false));
    yield put(getProjectsAction());
    yield put(toastSuccessAction('Folder deleted successfully'));
  } catch (e) {
    yield put(a.setShowDeleteProjectPopupAction(false));
    yield put(a.throwProjectsManagementErrorAction(e.message));
  }
}

function* getProjectsManagementItemWorker({ payload }) {
  try {
    const { data } = yield call(Api.getProject, payload);
    yield put(a.setProjectDataAction(data));
  } catch (e) {
    yield put(a.throwProjectsManagementErrorAction(e.message));
  }
}

function* editProjectsManagementItemWorker({ payload }) {
  try {
    const { id, version } = payload;
    const owner = yield select(getUserId);
    const formValue = yield select(getProjectFormSelector);
    const users = formValue.users ? formValue.users.map(user => user.id) : [];
    const requestData = {
      id,
      data: {
        ...formValue,
        users,
        owner,
        id,
        version,
      },
    };
    yield call(Api.editProject, requestData);
    yield put(a.setEditProjectIdPopupAction(false));
    yield put(a.getProjectsManagementDataAction());
    yield put(getProjectsAction());
    yield put(toastSuccessAction('Folder saved successfully'));
  } catch (e) {
    yield put(a.throwProjectPopupErrorAction(e.message));
  }
}

function* getUsersByDomainDataWorker() {
  try {
    const { data } = yield call(Api.getUserByDomain);
    // const users = data.map(item => ({ id: item.id, name: item.fullName, email: item.email }));
    yield put(a.setUsersByDomainAction(data));
  } catch (e) {
    yield put(a.throwProjectsManagementErrorAction(e.message));
  }
}

function* createProjectWorker(action) {
  try {
    const { version } = action.payload;
    const owner = yield select(getUserId);
    const formValue = yield select(getProjectFormSelector);
    let users = formValue.users ? formValue.users.map(user => user.id) : [];
    users = [...users, owner];
    const requestData = {
      ...formValue,
      users,
      owner,
      version,
    };
    yield call(Api.createProject, requestData);
    yield put(a.setShowCreateProjectPopupAction(false));
    yield put(a.getProjectsManagementDataAction());
    yield put(getProjectsAction());
    yield put(toastSuccessAction('Folder saved successfully'));
  } catch (e) {
    yield put(a.throwProjectPopupErrorAction(e.message));
  }
}

function* relationMapManagement() {
  yield takeEvery(a.getProjectsManagementDataAction, getProjectsManagementDataWorker);
  yield takeEvery(a.deleteProjectsManagementItemAction, deleteProjectsManagementItemWorker);
  yield takeEvery(a.getProjectDataAction, getProjectsManagementItemWorker);
  yield takeEvery(a.editProjectsManagementItemAction, editProjectsManagementItemWorker);
  yield takeLatest(a.getUsersByDomainAction, getUsersByDomainDataWorker);
  yield takeLatest(a.createProjectsManagementItemAction, createProjectWorker);
}

export default relationMapManagement;
