import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Measure from 'react-measure';
import { startOfDay, isAfter } from 'date-fns';

// Icons
import { IoMdAdd } from 'react-icons/io';
import { MdDelete, MdEdit } from 'react-icons/md';
// Components
import Loader from '../../common/Loader/Loader';
import Error from '../../common/Error/Error';
import Button from '../../common/Buttons/Button/Button';
import SimpleTable from '../../common/SimpleTable/SimpleTable';
import Input from '../../common/Inputs/Input/Input';
import ConfirmationDialog from '../../Modals/ConfirmationDialog/ConfirmationDialog';
// Store
import * as ACTIONS from './store/reducer';
import * as SELECTORS from './store/selectors';
import { isProjectManagerRightSelector, isFreeUserSelector, getUserId } from '../../Header/selectors';
// Utils
import { formatDate } from '../../Utils/Utils';
import { RELATIVE_PATH } from '../../../constantsCommon';
import { ProjectStatus } from './constants';
import { withRouter } from '../../common/WithRouter/WithRouter';
// Styles
import './styles.scss';
import {setSetManagementActiveProjectIdAction} from '../MyProjectsPage/reducer';

const propTypes = {
  projects: PropTypes.instanceOf(Array),
  getProjectsManagementData: PropTypes.func,
  filterProjectsManagementData: PropTypes.func,
  sortProjectsManagementData: PropTypes.func,
  sorting: PropTypes.instanceOf(Object),
  projectsNameFilter: PropTypes.string,
  setShowCreateProjectPopup: PropTypes.func,
  setShowDeleteProjectPopup: PropTypes.func,
  setShowEditProjectPopup: PropTypes.func,
  editProjectsManagementItem: PropTypes.func,
  createProjectsManagementItem: PropTypes.func,
  deleteProjectsManagementItem: PropTypes.func,
  showCreateProjectPopup: PropTypes.bool,
  showDeleteProjectPopup: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  isAdminRights: PropTypes.bool,
  isFreeUser: PropTypes.bool,
  userId: PropTypes.string,
  navigate: PropTypes.func,
  setActiveProjectId: PropTypes.func,
  toggleProjectsManagementDialog: PropTypes.func,
};

class ProjectsManagement extends React.Component {
  state = {
    projectIdToDelete: null,
  };
  simpleTableRef = React.createRef(null);

  componentDidMount() {
    const { getProjectsManagementData, isFreeUser, navigate } = this.props;
    getProjectsManagementData();

    if (isFreeUser) {
      navigate(`${RELATIVE_PATH}/research`);
    }
  }

  componentDidUpdate() {
    if (this.simpleTableRef.current) {
      this.simpleTableRef.current.recomputeRowHeights();
    }
  }

  filterByProjectName = (e) => {
    const { target: { value } } = e;
    const { filterProjectsManagementData } = this.props;

    filterProjectsManagementData({
      key: 'projectName',
      value,
    });
  };

  handleSort = (sortBy, sortDirection, sortValueMapper) => {
    const { sortProjectsManagementData } = this.props;

    sortProjectsManagementData({
      sortBy,
      sortDirection,
      ...(sortValueMapper && { sortValueMapper }),
    });
  };

  getRowHeight = () => 50;

  closeConfirmDeletePopup = () => {
    const { setShowDeleteProjectPopup } = this.props;
    this.setState({
      projectIdToDelete: null,
    });
    setShowDeleteProjectPopup(false);
  };

  removeSetManagementDataItem = () => {
    const { deleteProjectsManagementItem } = this.props;
    const { projectIdToDelete } = this.state;
    deleteProjectsManagementItem(projectIdToDelete);
  };

  handleEditProject = ({ id, version }) => {
    const { setShowEditProjectPopup } = this.props;
    setShowEditProjectPopup({ id, version });
  };

  handleConfirmDeletePopup = ({ id }) => {
    const { setShowDeleteProjectPopup } = this.props;
    this.setState({
      projectIdToDelete: id,
    });
    setShowDeleteProjectPopup(true);
  };

  handleCreateProjectModal = () => {
    const { setShowCreateProjectPopup, showCreateProjectPopup } = this.props;
    setShowCreateProjectPopup(!showCreateProjectPopup);
  };

  getColumns = (width) => {
    const {
      projectsNameFilter, userId, isAdminRights, isFreeUser, navigate
    } = this.props;
    const noRightsToWorkWithData = (rowData) => {
      const userIsProjectOwner = userId === rowData.owner;
      if (isAdminRights && (userIsProjectOwner || rowData.users.includes(userId))) {
        return false;
      } else if (!isFreeUser && userIsProjectOwner) {
        return false;
      } return true;
    };

    const colWidthBig = width / 2;
    const colWidthSmall = width / 8;
    return [
      {
        label: 'Folder name',
        dataKey: 'name',
        width: colWidthBig,
        disableSort: true,
        cellRenderer: ({ rowData }) => {
          let title = `Title: ${rowData.name}`;
          if (rowData.description && rowData.description.length > 0) title += `\n${rowData.description}`;
          return (
            <div
              title={title}
            >
              {rowData.name}
            </div>
          );
        },
        headerRenderer: ({
          dataKey,
          label,
          sortBy: headerSortBy,
          sortDirection: headerSortDirection,
        }) => {
          const ascClasses = classNames({
            'fa fa-sort-asc': true,
            'active': headerSortBy === dataKey && headerSortDirection === 'ASC',
          });
          const descClasses = classNames({
            'fa fa-sort-desc': true,
            'active': headerSortBy === dataKey && headerSortDirection === 'DESC',
          });
          return (
            <div className="header-section">
              <span className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle" title={label}>
                {label}
              </span>
              <span className="sorting-section">
                <i
                  role="presentation"
                  className={ascClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'ASC');
                  }}
                />
                <i
                  role="presentation"
                  className={descClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'DESC');
                  }}
                />
              </span>
              <Input
                customClassName="header-input input"
                placeholder="Search folder name"
                type="input"
                onChange={this.filterByProjectName}
                value={projectsNameFilter}
              />
            </div>
          );
        },
      },
      {
        label: 'Users',
        dataKey: 'usersCount',
        width: colWidthSmall,
        headerRenderer: ({
          dataKey,
          label,
          sortBy: headerSortBy,
          sortDirection: headerSortDirection,
        }) => {
          const ascClasses = classNames({
            'fa fa-sort-asc': true,
            'active': headerSortBy === dataKey && headerSortDirection === 'ASC',
          });
          const descClasses = classNames({
            'fa fa-sort-desc': true,
            'active': headerSortBy === dataKey && headerSortDirection === 'DESC',
          });
          return (
            <div className="header-section">
              <span
                className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle"
                title={label}
              >
                {label}
              </span>
              <span className="sorting-section">
                <i
                  role="presentation"
                  className={ascClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'ASC');
                  }}
                />
                <i
                  role="presentation"
                  className={descClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'DESC');
                  }}
                />
              </span>
            </div>
          );
        },
        cellRenderer: ({ rowData }) => {
          const usersAreHidden = noRightsToWorkWithData(rowData);
          return (
            !usersAreHidden &&
            <div
              className="tag-section"
            >
              {rowData.usersCount}
            </div>
          );
        },
      },
      {
        label: 'Analytics',
        dataKey: 'analyticsCount',
        width: colWidthSmall,
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <div
            className="tag-section link"
            onClick={() => {
              this.props.toggleProjectsManagementDialog();
              this.props.setActiveProjectId(rowData.id);
              navigate(
                `${RELATIVE_PATH}/my-projects`
              );
            }}
          >
            {rowData.analyticsCount}
          </div>
        ),
      },
      {
        label: 'Sets',
        dataKey: 'setsCount',
        width: colWidthSmall,
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <div
            className="tag-section link"
            onClick={() => {
              this.props.toggleProjectsManagementDialog();
              this.props.setActiveProjectId(rowData.id);
              navigate(
                `${RELATIVE_PATH}/my-projects`
              );
            }}
          >
            {rowData.setsCount}
          </div>
        ),
      },
      {
        label: 'Relation maps',
        dataKey: 'mapCount',
        width: colWidthSmall,
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <div
            className="tag-section link"
            onClick={() => {
              this.props.toggleProjectsManagementDialog();
              this.props.setActiveProjectId(rowData.id);
              navigate(
                `${RELATIVE_PATH}/my-projects`
              );
            }}
          >
            {rowData.mapCount}
          </div>
        ),
      },
      {
        label: 'Status',
        dataKey: 'status',
        width: colWidthSmall,
        disableSort: true,
        cellRenderer: ({ rowData }) => (
          <div
            className="tag-section"
          >
            {ProjectStatus[rowData.status] && ProjectStatus[rowData.status].label}
          </div>
        ),
      },
      {
        label: 'Last edited',
        dataKey: 'lastEditDate',
        width: colWidthSmall,
        disableSort: true,
        headerRenderer: ({
          dataKey,
          label,
          sortBy: headerSortBy,
          sortDirection: headerSortDirection,
        }) => {
          const ascClasses = classNames({
            'fa fa-sort-asc': true,
            'active': headerSortBy === dataKey && headerSortDirection === 'ASC',
          });
          const descClasses = classNames({
            'fa fa-sort-desc': true,
            'active': headerSortBy === dataKey && headerSortDirection === 'DESC',
          });
          return (
            <div className="header-section">
              <span
                className="ReactVirtualized__Table__headerTruncatedText vertical-align-middle"
                title={label}
              >
                {label}
              </span>
              <span className="sorting-section">
                <i
                  role="presentation"
                  className={ascClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'ASC');
                  }}
                />
                <i
                  role="presentation"
                  className={descClasses}
                  onClick={() => {
                    this.handleSort(dataKey, 'DESC');
                  }}
                />
              </span>
            </div>
          );
        },
        cellRenderer: ({ rowData }) => {
          if (!rowData.lastEditDate) {
            return null;
          }
          const lastEditDate = isAfter(startOfDay(new Date()), new Date(rowData.lastEditDate)) ?
            formatDate(rowData.lastEditDate) :
            formatDate(rowData.lastEditDate, 'HH:mm');
          return (
            <span>{lastEditDate}</span>
          );
        },
      },
      {
        dataKey: 'id',
        disableSort: true,
        width: colWidthSmall,
        className: 'table-wrap__cell--left',
        cellRenderer: ({ rowData }) => {
          const iconsAreHidden = noRightsToWorkWithData(rowData);
          const iconClassName = classNames({
            'management-controls__item': true,
            'visually-hidden': iconsAreHidden,
          });
          return (
            <div className="relation-map-controls management-controls">
              <div
                title="Edit folder"
                className={iconClassName}
                onClick={() => { this.handleEditProject(rowData); }}
              >
                <MdEdit size={28} />
              </div>
              <div
                title="Delete folder"
                className={iconClassName}
                onClick={() => { this.handleConfirmDeletePopup(rowData); }}
              >
                <MdDelete size={28} />
              </div>
            </div>
          );
        },
      },
    ];
  };

  render() {
    const {
      error,
      projects,
      sorting: { sortBy, sortDirection },
      loading,
      showDeleteProjectPopup,
    } = this.props;

    const isLoading = loading;
    const tableSettings = {
      rowHeight: this.getRowHeight,
      headerHeight: 65,
      sortBy,
      sortDirection,
    };

    return (
      <div className="projects-management">
        {
          !error && projects &&
          <div className="row projects-management-container m-0">
            <div className="management-container__buttons">
              <Button
                customClassName="management__create-button button-primary"
                onClick={this.handleCreateProjectModal}
              >
                <IoMdAdd size={28} />
                Create
              </Button>
            </div>
            {
              projects !== null &&
              <Measure offset={true}>
                {({ measureRef, contentRect }) => (
                  <div className="projects-info" ref={measureRef}>
                    {
                      !!contentRect.offset.width && !!contentRect.offset.height &&
                      <SimpleTable
                        innerRef={this.simpleTableRef}
                        data={projects}
                        width={contentRect.offset.width}
                        height={contentRect.offset.height}
                        columns={this.getColumns(contentRect.offset.width)}
                        settings={tableSettings}
                        manageable={false}
                        actions={this.actions}
                        sortAction={ACTIONS.sortProjectsManagementDataAction}
                        fixedHeight={true}
                        noRowsMsg="There is no data to display"
                      />
                    }
                  </div>
                )}
              </Measure>
            }
          </div>
        }
        <Error
          error={error}
          show={!!error && !isLoading}
          customClassName="text-center error-text"
        />
        <Loader
          isLoading={loading && !error}
          withOverlay={true}
        />
        <ConfirmationDialog
          onConfirm={this.removeSetManagementDataItem}
          onCancel={this.closeConfirmDeletePopup}
          isOpen={showDeleteProjectPopup}
          closeCb={this.closeConfirmDeletePopup}
          text={
            'Deleting folder will delete all projects contained in the folder. \n' +
            'Are you sure?'
          }
        />
      </div>
    );
  }
}

ProjectsManagement.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    error: SELECTORS.getProjectsManagementErrorSelector(state),
    loading: SELECTORS.getProjectsManagementLoadingSelector(state),
    projects: SELECTORS.getProjectsManagementDataSelector(state),
    sorting: SELECTORS.getProjectsSortingSelector(state),
    userNameFilter: SELECTORS.getUserNameFilterSelector(state),
    projectsNameFilter: SELECTORS.getProjectsNameFilterSelector(state),
    projectData: SELECTORS.getProjectDataSelector(state),
    showDeleteProjectPopup: SELECTORS.showDeleteProjectPopupSelector(state),
    showCreateProjectPopup: SELECTORS.showCreateProjectPopupSelector(state),
    isFreeUser: isFreeUserSelector(state),
    isAdminRights: isProjectManagerRightSelector(state),
    userId: getUserId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectsManagementData() {
      dispatch(ACTIONS.getProjectsManagementDataAction());
    },
    filterProjectsManagementData(data) {
      dispatch(ACTIONS.filterProjectsManagementDataAction(data));
    },
    sortProjectsManagementData(sorting) {
      dispatch(ACTIONS.sortProjectsManagementDataAction(sorting));
    },
    setShowCreateProjectPopup(data) {
      dispatch(ACTIONS.setShowCreateProjectPopupAction(data));
    },
    setShowDeleteProjectPopup(data) {
      dispatch(ACTIONS.setShowDeleteProjectPopupAction(data));
    },
    setShowEditProjectPopup(data) {
      dispatch(ACTIONS.setEditProjectIdPopupAction(data));
    },
    deleteProjectsManagementItem(id) {
      dispatch(ACTIONS.deleteProjectsManagementItemAction(id));
    },
    editProjectsManagementItem(data) {
      dispatch(ACTIONS.editProjectsManagementItemAction(data));
    },
    createProjectsManagementItem(data) {
      dispatch(ACTIONS.createProjectsManagementItemAction(data));
    },
    setActiveProjectId(data) {
      dispatch(setSetManagementActiveProjectIdAction(data));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectsManagement));
