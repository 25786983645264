import React from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from 'react-dd-menu';
import { connect } from 'react-redux';
// Styles
import './MyProjectTypeFilterDropdown.scss';
import {MdOutlineFilterAlt} from 'react-icons/md';
import {filterProjectMenuCloseAction, filterProjectMenuOpenAction, setFilterProjectOptionAction} from '../../reducer';
import {getFilterDropDownOpenKey, getProjectsFilterSelectedOption} from '../../selectors';
import {IoIosRadioButtonOff, IoIosRadioButtonOn} from 'react-icons/io';
import {PROJECT_TYPE_ALL, ProjectTypes} from '../../enum';

const propTypes = {
  open: PropTypes.bool,
  menuOpen: PropTypes.func,
  menuClose: PropTypes.func,
  changePassword: PropTypes.func,
  logout: PropTypes.func,
  helpIsOpen: PropTypes.string,
  setHelpIsOpen: PropTypes.func,
  isEnabled: PropTypes.bool,
  selectedFilter: PropTypes.string,
  selectFilterValue: PropTypes.func,
};

const MyProjectTypeFilterDropdown = (props) => {
  const {
    open,
    menuOpen,
    menuClose,
    selectedFilter,
    selectFilterValue,
  } = props;

  function toggle() {
    if (open) {
      menuClose();
    } else {
      menuOpen();
    }
  }

  const menuOptions = {
    isOpen: open,
    close: menuClose,
    toggle: (
      <div onClick={toggle} className="project-filter-block">
        <MdOutlineFilterAlt  size={26} />
      </div>
    ),
    align: 'left',
    textAlign: 'left',
  };

  function radioIcon(item) {
    if (item === selectedFilter) {
      return <IoIosRadioButtonOn />;
    } else {
      return <IoIosRadioButtonOff />;
    }
  }

  function filterClick(item) {
    selectFilterValue(item);
  }

  return <div>
    <DropdownMenu {...menuOptions}>
      <li>
        <span
          className="dd-menu-item not-clickable-item"
          onClick={() => filterClick(PROJECT_TYPE_ALL)}
        >
          {radioIcon(PROJECT_TYPE_ALL)} All
        </span>
      </li>
      <li>
        <span
          className="dd-menu-item not-clickable-item"
          onClick={() => filterClick(ProjectTypes.SIMPLE_SET)}
        >
          {radioIcon(ProjectTypes.SIMPLE_SET)} Simple Set
        </span>
      </li>
      <li>
        <span
          className="dd-menu-item not-clickable-item"
          onClick={() => filterClick(ProjectTypes.EFFECT_SET)}
        >
          {radioIcon(ProjectTypes.EFFECT_SET)} Effect Set
        </span>
      </li>
      <li>
        <span
          className="dd-menu-item not-clickable-item"
          onClick={() => filterClick(ProjectTypes.COMPLEX_SET)}
        >
          {radioIcon(ProjectTypes.COMPLEX_SET)} Complex Set
        </span>
      </li>
      <li>
        <span
          className="dd-menu-item"
          onClick={() => filterClick(ProjectTypes.RELATION_MAP)}
        >
          {radioIcon(ProjectTypes.RELATION_MAP)} Relation Map
        </span>
      </li>
      <li>
        <span
          className="dd-menu-item"
          onClick={() => filterClick(ProjectTypes.ANALYTICS)}
        >
          {radioIcon(ProjectTypes.ANALYTICS)} Analytics
        </span>
      </li>
    </DropdownMenu>
  </div>;
};

MyProjectTypeFilterDropdown.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    open: getFilterDropDownOpenKey(state),
    selectedFilter: getProjectsFilterSelectedOption(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    menuOpen() {
      dispatch(filterProjectMenuOpenAction());
    },
    menuClose() {
      dispatch(filterProjectMenuCloseAction());
    },
    selectFilterValue(data) {
      dispatch(setFilterProjectOptionAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProjectTypeFilterDropdown);
