import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

import { defaultProjects } from '../../Projects/ProjectsTabs/constants';
import {PROJECT_TYPE_ALL} from './enum';

export const getMyProjectManagementDataAction = createAction('@@myProjects/GET_DATA');
export const putMyProjectsDataAction = createAction('@@myProjects/PUT_DATA');
export const toggleSetManagementDataLoaderAction = createAction('@@myProjects/TOGGLE_DATA_LOADER');
export const throwSetManagementDataErrorAction = createAction('@@myProjects/THROW_DATA_ERROR');
export const filterSetManagementDataAction = createAction('@@myProjects/FILTER_DATA');
export const sortSetManagementDataAction = createAction('@@myProjects/SORT_DATA');
export const removeSetManagementDataItemAction = createAction('@@myProjects/REMOVE_DATA_ITEM');
export const selectSetManagementDataItemForAnalysisAction = createAction('@@myProjects/SELECT_DATA_ITEM_FOR_ANALYSIS');
export const resetSetManagementDataAction = createAction('@@myProjects/RESET_DATA');

export const checkAllSetsAction = createAction('@@myProjects/CHECK_ALL_SETS_SELECTION_TABLE');
export const checkSetAction = createAction('@@myProjects/CHECK_ITEM_SET_SELECTION_TABLE');
export const duplicateSetsAction = createAction('@@myProjects/DUPLICATE_SETS');

export const getSetForFilteringAction = createAction('@@myProjects/GET_SET_FOR_FILTERING');
export const putSetForFilteringAction = createAction('@@myProjects/PUT_SET_FOR_FILTERING');
export const resetSetForFilteringAction = createAction('@@myProjects/RESET_SET_FOR_FILTERING');

export const downloadMyProjectDataAction = createAction('@@myProjects/DOWNLOAD_MYPROJECT_DATA');

export const clearSetManagementActiveProjectIdAction = createAction('@@myProjects/CLEAR_ACTIVE_PROJECT_ID');
export const setSetManagementActiveProjectIdAction = createAction('@@myProjects/SET_ACTIVE_PROJECT_ID');

export const myProjectManagementSearchRedirectAction = createAction('@@myProjects/SEARCH_REDIRECT');

const PROJECT_FILTER_PREFIX = 'FILTER_PROJECT_DROPDOWN';
export const setFilterProjectOptionAction = createAction(`@@myProjects/${PROJECT_FILTER_PREFIX}/SET_FILTER`);
export const filterProjectMenuOpenAction = createAction(`@@myProjects/${PROJECT_FILTER_PREFIX}/OPEN`);
export const filterProjectMenuCloseAction = createAction(`@@myProjects/${PROJECT_FILTER_PREFIX}/CLOSE`);

const initialState = fromJS({
  data: null,
  checkedSets: [],
  loading: false,
  error: null,
  set: null,
  filters: {
    titleFilter: '',
    tagFilter: '',
  },
  sorting: {
    sortBy: 'lastOpened',
    sortDirection: 'DESC',
  },
  activeProjectId: defaultProjects[0].id,
  projectsFilterSelectedOption: PROJECT_TYPE_ALL,
  openProjectsFilter: false,
});

const reducer = handleActions(
  {
    [putMyProjectsDataAction]: (state, { payload }) =>
      state
        .update('data', () => fromJS(payload))
        .update('error', () => null)
        .update('loading', () => false),
    [putSetForFilteringAction]: (state, { payload }) =>
      state
        .update('set', () => fromJS(payload)),
    [resetSetForFilteringAction]: state =>
      state
        .update('set', () => null),
    [toggleSetManagementDataLoaderAction]: (state, { payload }) =>
      state
        .update('error', () => null)
        .update('loading', () => payload),
    [throwSetManagementDataErrorAction]: (state, { payload }) =>
      state.update('error', () => payload),
    [sortSetManagementDataAction]: (state, { payload }) =>
      state.update('sorting', () => fromJS(payload)),
    [filterSetManagementDataAction]: (state, { payload }) =>
      state.updateIn(['filters', payload.filter], () => payload.value),
    [resetSetManagementDataAction]: (state) => 
      state
        .merge({
          ...initialState,
          activeProjectId: state.get('activeProjectId'),
        }),
    [checkAllSetsAction]: (state, { payload }) =>
      state
        .update('data', data => (
          data.map(item => (
            item.merge(fromJS({ selected: payload }))
          ))
        )),
    [checkSetAction]: (state, { payload }) =>
      state
        .update('data', data => (
          data.map(item => (
            item.get('id') === payload.id ?
              item.merge(fromJS({ selected: payload.checked })) :
              item
          ))
        )),
    [setSetManagementActiveProjectIdAction]: (state, { payload }) =>
      state.update('activeProjectId', () => payload),
    [clearSetManagementActiveProjectIdAction]: state =>
      state
        .update('activeProjectId', () => initialState.get('activeProjectId')),
    [setFilterProjectOptionAction]: (state, { payload }) =>
      state
        .update('projectsFilterSelectedOption', () => fromJS(payload)),
    [filterProjectMenuOpenAction]: state =>
      state.set('openProjectsFilter', true),
    [filterProjectMenuCloseAction]: state =>
      state.set('openProjectsFilter', false),
  },
  initialState
);

export default reducer;
